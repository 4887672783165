@font-face {
  font-family: 'Segoe UI';
  src: url('/src/static/fonts/SegoeUI.ttf') format('woff');
  font-style: normal;
  font-weight: 400;
}

body {
  margin: 0;
  font-family: "Segoe UI Emoji", "Segoe UI Symbol", "Segoe UI", "Apple Color Emoji",
    "Twemoji Mozilla", "Noto Color Emoji", "Android Emoji",
    -apple-system, BlinkMacSystemFont, Arial, 'Noto Sans', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4,
p,
span {
  margin: 0;
  padding: 0;
}

:root {
  --bcn-color-white: #ffffff;
  --bcn-color-white-001: rgba(255, 255, 255, 0.9);

  --bcn-color-gray-001: #F3F3F3;
  --bcn-color-gray-002: #DCDCDC;
  --bcn-color-gray-003: #EEEEEE;
  --bcn-color-gray-004: #E7E7E7;
  --bcn-color-gray-005: #F0F0F0;
  --bcn-color-gray-006: #d9d9d9;
  --bcn-color-gray-007: #CECECE;

  --bcn-color-black: #000000;
  --bcn-color-black-001: rgba(0, 0, 0, 0.9);
  --bcn-color-black-002: rgba(0, 0, 0, 0.6);
  --bcn-color-black-003: rgba(0, 0, 0, 0.4);
  --bcn-color-black-004: rgba(0, 0, 0, 0.1);
  --bcn-color-black-005: #242424;
  --bcn-color-black-006: #616161;
  --bcn-color-black-007: #424242;
  --bcn-color-black-008: #131523;
  --bcn-color-black-009: #23272E;
  --bcn-color-black-010: #808080;
  --bcn-color-black-011: rgba(38, 44, 71, 0.16);
  --bcn-color-black-012: rgba(0, 0, 0, 0.05);
  --bcn-color-black-013: #0A0A0A;
  --bcn-color-black-014: rgba(0, 0, 0, 0.26);

  --bcn-color-blue-001: #0F6CBD;
  --bcn-color-blue-002: #187ED7;
  --bcn-color-blue-003: #001536;
  --bcn-color-blue-004: #DAEEFF;
  --bcn-color-blue-005: #1BD5EF;
  --bcn-color-blue-006: #5A607F;
  --bcn-color-blue-007: #1C2A53;
  --bcn-color-blue-008: #F1F8FF;
  --bcn-color-blue-009: #F2F3FF;

  --bcn-color-red-001: #D54941;
  --bcn-color-red-002: #FFF0ED;
  --bcn-color-red-003: #CA3994;
  --bcn-color-red-004: #F5505D;
  --bcn-color-red-005: #F6685D;

  --bcn-color-orange-001: #E37318;
  --bcn-color-orange-002: #FFF1E9;
  --bcn-color-orange-003: #FA9550;
  --bcn-color-orange-004: #FF985E;

  --bcn-color-green-001: #2BA471;
  --bcn-color-green-002: #E3F9E9;
  --bcn-color-green-003: #56C08D;

  --bcn-color-purple-001: #7C4CCA;

  --max-width: 980px;
}

/* ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
} */

::-webkit-scrollbar {
  width: 8;
  background-color: 'transparent'
}


::-webkit-scrollbar-thumb {
  background-Color: rgba(44, 44, 44, 0.25);
  border-radius: 4
}

/* ::-webkit-scrollbar-track {
  background-color: #ffffff;
} */

/* ::-webkit-scrollbar-thumb {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 6px rgb(139, 139, 139);
} */

#webpack-dev-server-client-overlay {
  display: none !important;
}