.root {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 16px;
}

.company {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    color: var(--bcn-color-white-001);
}

.user_root {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    color: var(--bcn-color-white-001);
    cursor: pointer;

}

.iconBox {
    display: flex;
    align-items: center;
    justify-content: center;
}

.name {
    display: flex;
    align-items: center;
    justify-content: center;
}

.root :global .ant-dropdown-menu-title-content .ant-btn {
    width: 100%;
}